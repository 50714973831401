import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export default function makeCalendar() {
  const fullRange = Array.from(
    moment.range("2022-01-01", "2022-12-28").by("days")
  );
  const fullRangeMonthly = {};
  fullRange.map((d) => {
    const month = moment(d).format("M");
    const week = moment(d).format("w");
    if (!fullRangeMonthly[month]) fullRangeMonthly[month] = {};
    if (!fullRangeMonthly[month][week]) fullRangeMonthly[month][week] = [];
    fullRangeMonthly[month][week].push(d);
  });
  Object.keys(fullRangeMonthly).map((month) => {
    Object.keys(fullRangeMonthly[month]).map((week) => {
      let cr = fullRangeMonthly[month][week];
      if (cr.length !== 7) {
        if (moment(cr[0]).format("D") == 1)
          cr = [...Array(7 - cr.length).fill("x"), ...cr];
        else cr = [...cr, ...Array(7 - cr.length).fill("x")];
        fullRangeMonthly[month][week] = cr;
      }
    });
  });
  return fullRangeMonthly;
}
