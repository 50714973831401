module.exports = {
  name: "gregorian_tr",
  months: [
    ["Ocak", "Ocak"],
    ["Şubat", "Şubat"],
    ["Mart", "Mart"],
    ["Nisan", "Nisan"],
    ["Mayıs", "Mayıs"],
    ["Haziran", "Haziran"],
    ["Temmuz", "Temmuz"],
    ["Ağustos", "Ağustos"],
    ["Eylül", "Eylül"],
    ["Ekim", "Ekim"],
    ["Kasım", "Kasım"],
    ["Aralık", "Aralık"],
  ],
  weekDays: [
    ["Saturday", "Cts"],
    ["Sunday", "Paz"],
    ["Monday", "Pzt"],
    ["Tuesday", "Sal"],
    ["Wednesday", "Çrş"],
    ["Thursday", "Prş"],
    ["Friday", "Cum"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};
