const dictionary = {
  private: "Özel",
  rectangle: "dikdörtgen",
  "cleaning service": "Temizlik Hizmeti",
};

export default function translate(code) {
  if (!code) return "";
  return dictionary[code.toLowerCase()] || code;
}
