import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/HeaderBlog";
import { Grid, Container } from "@material-ui/core";
import ListingDetailTab from "../../components/listingDetailTab/ListingDetailTab";
import Footer from "../../components/footer/Footer";
import "../listingDetail/ListingDetail.css";
import ListingDetailMakeResevation from "../../components/listingDetailMakeReservation/ListingDetailMakeReservation";
import { Listing } from "../../Models";
import useAPI from "../../hooks/useAPI";
import { Helmet } from "react-helmet";
import { CDN_BASE_URL } from "../../config";
import PropertyCard from "../../components/propertyCard/PropertyCard";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import AppCalendar from "../../components/appcalendar/AppCalendar";

const ListingDetail = (props) => {
  const [similarVillas, setSimilarVillas] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [width, setWidth] = useState(1200);

  const getData = async (slug) => {
    const response = await Listing.getPropertyDetails(slug);
    if (response.data.status === 200) setData(response.data.data[0]);
  };

  const getSimilarVillas = async () => {
    const response = await Listing.getSimilarVillas(slug);
    if (response.ok) setSimilarVillas(response.data);
  };

  useEffect(() => {
    getData(slug);
    getSimilarVillas();
    setWidth(window.innerWidth);
  }, []);

  const sliders = data?.images
    ?.sort((a, b) => a.order - b.order)
    .map((item) => ({
      url: encodeURI(CDN_BASE_URL + "/" + (item.webp ?? item.filename)),
    }));

  const renderSlider = (sliders) => {
    return (
      <ImageGallery
        items={sliders.map((item) => ({
          original: item.url,
          thumbnail: item.url,
        }))}
      />
    );
  };

  if (!data) return <>Loading ... </>;

  return (
    <div style={{ backgroundColor: "#F9F9F9" }}>
      <Header />
      <>
        <Helmet>
          <title>Villabizde | {data.general.propertyTitle}</title>
          <meta name="description" content={data.seo?.seoDesc} />
          <meta name="keywords" content={data.seo?.seoKeyword} />
          <meta property="og:title" content={`VillaBizde | ${data.general.propertyTitle}`} />
          <meta property="og:url" content={`https://villabizde.net/listingDetail/${data.slug}`} />
          <meta property="og:description" content={data.seo?.seoDesc} />
          <meta property="og:image" content={sliders ? sliders[0].url : ""} />
        </Helmet>
        <Container>
          <Grid container spacing={2} style={{ paddingTop: 10 }}>
            <Grid item xs={12} lg={7}>
              {data.images?.length > 0 && renderSlider(sliders)}
              {width < 1200 && (
                <div
                  style={{
                    marginBlock: 10,
                    backgroundColor: "white",
                  }}
                >
                  <ListingDetailMakeResevation info={data} />
                </div>
              )}
              <ListingDetailTab propertyInfo={data} />
            </Grid>
            <Grid item xs={12} lg={5}>
              <div
                style={{
                  border: "1px solid #EEE",
                  backgroundColor: "#FFF",
                }}
              >
                <div style={{ padding: 10, borderBottom: "1px solid #EEE" }}>
                  <span>{data.general.propertyTitle}</span>
                </div>
                <Grid container spacing={2} style={{ padding: 20 }}>
                  <Grid
                    item
                    xs={6}
                    lg={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={`/icons/people.png`} style={{ height: 50, width: 50 }} />
                    <div
                      style={{
                        marginTop: 10,
                        fontSize: "1rem",
                        backgroundColor: "#073665",
                        height: 30,
                        width: 30,
                        color: "white",
                        borderRadius: 3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {data.allowedPeople?.adults}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={`/icons/bed.png`} style={{ height: 50, width: 50 }} />
                    <div
                      style={{
                        marginTop: 10,
                        fontSize: "1rem",
                        backgroundColor: "#073665",
                        height: 30,
                        width: 30,
                        color: "white",
                        borderRadius: 3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {data.features.mainFeatures?.roomNumber}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={`/icons/bathroom.png`} style={{ height: 50, width: 50 }} />
                    <div
                      style={{
                        marginTop: 10,
                        fontSize: "1rem",
                        backgroundColor: "#073665",
                        height: 30,
                        width: 30,
                        color: "white",
                        borderRadius: 3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {data.features.mainFeatures?.bathroomNumber}
                    </div>
                  </Grid>
                </Grid>
              </div>
              {width > 1200 && (
                <div
                  style={{
                    position: "sticky",
                    marginTop: 10,
                    backgroundColor: "white",
                  }}
                >
                  <ListingDetailMakeResevation info={data} />
                </div>
              )}
              <div
                style={{
                  border: "1px solid #EEE",
                  backgroundColor: "#FFF",
                  marginTop: 10,
                }}
              >
                <div style={{ padding: 10, borderBottom: "1px solid #EEE" }}>
                  <span>Fiyata Dahil Olanlar</span>
                </div>
                <Grid container spacing={2} style={{ padding: 20 }}>
                  {[
                    { icon: "electric", title: "Elektrik" },
                    { icon: "water", title: "Su" },
                    { icon: "wifi", title: "İnternet" },
                    { icon: "fire", title: "Tüpgaz" },
                  ].map((item) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        lg={3}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={`/icons/${item.icon}.png`} style={{ height: 50, width: 50 }} />
                        <span style={{ marginTop: 5, fontSize: "1rem" }}>{item.title}</span>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
              <div
                style={{
                  border: "1px solid #EEE",
                  backgroundColor: "#FFF",
                  marginTop: 10,
                }}
              >
                <div style={{ padding: 10, borderBottom: "1px solid #EEE" }}>
                  <span>Fiyata Dahil Olmayanlar</span>
                </div>
                <Grid container spacing={2} style={{ padding: 20 }}>
                  {[
                    { icon: "automobile", title: "Ulaşım Hizmeti" },
                    { icon: "hamburger", title: "Yemek Hizmeti" },
                    { icon: "bed", title: "Ekstra Yatak" },
                    { icon: "cleaning", title: "Ekstra Temizlik" },
                  ].map((item) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        lg={3}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={`/icons/${item.icon}.png`} style={{ height: 60, width: 60 }} />
                        <span style={{ marginTop: 5, fontSize: "1rem" }}>{item.title}</span>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
              <div
                style={{
                  border: "1px solid #EEE",
                  backgroundColor: "#FFF",
                  marginTop: 10,
                }}
              >
                <div style={{ padding: 10, borderBottom: "1px solid #EEE" }}>
                  <span>Kurallar</span>
                </div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    padding: 20,
                    aligItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {[
                    { icon: "baby", title: "Bebeklere uygun" },
                    { icon: "family", title: "Çocuklara uygun" },
                    {
                      icon: "dog",
                      title: "Evcil hayvanlara uygun değil",
                    },
                    { icon: "party", title: "Etkinlik düzenlenemez" },
                    { icon: "cigara", title: "Sigara içilemez" },
                  ].map((item) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        lg={4}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={`/icons/${item.icon}.png`} style={{ height: 60, width: 60 }} />
                        <span style={{ marginTop: 10, fontSize: "0.9rem" }}>{item.title}</span>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>

              {/* <Grid container spacing={1} style={{ marginTop: 10 }}>
                {(similarVillas || [])?.map((villa) => {
                  return (
                    <Grid item xs={12} md={6}>
                      <PropertyCard item={villa} />
                    </Grid>
                  );
                })}
              </Grid> */}
            </Grid>
          </Grid>
        </Container>
      </>

      <Footer />
      {modalOpen && (
        <Lightbox mainSrc={selectedImage?.url} onCloseRequest={() => setModalOpen(false)} />
      )}
    </div>
  );
};

export default ListingDetail;
