import { Grid } from "@material-ui/core";
import moment from "moment";
const turkishDays = ["PZTS", "SAL ", "ÇAR", "PER", "CUM ", "CMTS", "PAZ "];
const df = "YYYY-MM-DD";

export default function MonthBox({ monthName, monthData, reservationList }) {
  const renderClassName = (day) => {
    const fday = moment(day).format(df);
    if (reservationList.map((x) => x.date).includes(fday)) {
      const foundDate = reservationList.find((x) => x.date == fday);
      const { type, status } = foundDate;
      if (type === "entryDate") return "cal-day-disabled-in";
      if (type === "exitDate") return "cal-day-disabled-out";
      if (type === "normal")
        return status === "pending"
          ? "cal-day-disabled-pending"
          : "cal-day-disabled-verified";
    } else return "active";
  };
  return (
    <div
      style={{
        border: "1px solid #CECECE",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #CECECE",
          textAlign: "center",
          padding: 10,
          textTransform: "uppercase",
        }}
      >
        {monthName}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          padding: 10,
        }}
      >
        {[...Array(7).keys()].map((index) => {
          return (
            <div className="cal-day cal-day-header" style={{ flex: 1 }}>
              {turkishDays[index]}
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          paddingBlock: 10,
          height: "35vh",
        }}
      >
        {Object.keys(monthData).map((week) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                paddingBottom: 10,
                paddingLeft: 10,
              }}
            >
              {monthData[week].map((day) => {
                return (
                  <div className={`cal-day ${renderClassName(day)}`}>
                    {day !== "x" && moment(day).format("D")}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
