import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import MiniTextComponent from "./MiniTextComponent";
import { Parser } from "html-to-react";
import translate from "../../helpers/translate";

const useStyles = makeStyles({
  minicard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    backgroundColor: "#F5F5F5",
    borderWidth: 1,
    padding: 10,
  },
  miniicon: {
    fontSize: 18,
    color: "#4D4D4D",
    marginRight: 10,
  },
  headerTitle: {
    backgroundColor: "#EEE",
    marginTop: 10,
    padding: 10,
    textAlign: "left",
    verticalAlign: "middle",
  },
});

export default function TabDetails({ propInfo }) {
  const classes = useStyles();
  return (
    <div>
      <div>
        <div style={{ textAlign: "justify", color: "#073665" }}>
          {Parser().parse(propInfo.general.content || "")}
        </div>
      </div>

      {/* Pool Information */}
      {propInfo.features.pools?.pool && (
        <>
          <div className={classes.headerTitle}>
            <Typography variant="body1"> Havuz Bilgileri </Typography>
          </div>
          <Grid container style={{ marginTop: 5 }} spacing={1}>
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Yüzme Havuzu"}
                  value={translate(propInfo.features.pools.pool)}
                />
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Havuz Tipi"}
                  value={translate(propInfo.features.pools.poolType)}
                />
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Tam Korunaklı Havuzl"}
                  value={translate(propInfo.features.pools.religiousPool ? "Evet" : "Hayir")}
                />
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Havuz Derinliği"}
                  value={translate(propInfo.features.pools.poolDepth)}
                />
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Havuz Uzunluğu"}
                  value={translate(propInfo.features.pools.poolLength)}
                />
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Havuz Genişliği"}
                  value={translate(propInfo.features.pools.poolWidth)}
                />
              </div>
            </Grid>

            {/* Indoor Pool */}
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Kapalı Havuz"}
                  value={propInfo.features.pools.poolIndoor ? `Evet` : `Yok`}
                />
              </div>
            </Grid>
            {/* Indoor Pool Length */}
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Kapalı Havuz Uzunluğu"}
                  value={propInfo.features.pools.poolIndoorLength}
                />
              </div>
            </Grid>
            {/* Indoor Pool Height */}
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Kapalı Havuz Genişliği"}
                  value={propInfo.features.pools.poolIndoorWidth}
                />
              </div>
            </Grid>
            {/* Indoor Pool Height */}
            <Grid item xs={6} lg={6}>
              <div className={classes.minicard}>
                <MiniTextComponent
                  title={"Kapalı Havuz Derinliği"}
                  value={propInfo.features.pools.poolIndoorDepth}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {/* Features */}
      <div className={classes.headerTitle}>
        <Typography variant="body1"> Özellikler </Typography>
      </div>
      <Grid container spacing={1} style={{ paddingTop: 10 }}>
        {[...(propInfo.features.featureDetails?.extraFeatures || [])]
          .filter((ft) => ft.checked)
          .map((ft) => {
            return (
              <Grid item xs={6} md={4} lg={4}>
                <div className={classes.minicard}>
                  <CheckCircleOutline className={classes.miniicon} />
                  <Typography style={{ marginLeft: 5 }} noWrap variant="body2">
                    {ft.text}
                  </Typography>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}
