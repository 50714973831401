import React from "react";
import Button from "@material-ui/core/Button";
import "../campaignVilla/CampaignVilla.css";
import { Grid, Typography } from "@material-ui/core";
import PropertyCard from "../propertyCard/PropertyCard";

export default function VillaView({ villas = [], title, link = "/" }) {
  return (
    <>
      <div
        style={{
          paddingBlock: 10,
          paddingLeft: 10,
          marginBlock: 40,
          borderLeft: "5px solid #4d4d4d",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" color="#4D4D4D">
          {title}
        </Typography>
        <Button
          onClick={() => window.open(link, "_self")}
          variant="contained"
          color="primary"
        >
          Tümünü Göster
        </Button>
      </div>

      <Grid container spacing={2}>
        {villas.map((item) => (
          <Grid item xs={12} md={6} lg={4}>
            <PropertyCard item={item} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
