import { useEffect, useState } from "react";
import { getXRates } from "../Models/Rate";

const useCurrency = () => {
  const [rates, setRates] = useState([]);

  const getRates = async () => {
    const response = await getXRates();
    if (response.data.status === 200) {
      const newRates = response.data.data;
      setRates(newRates);
      localStorage.setItem("rates", JSON.stringify(newRates));
    }
  };

  useEffect(() => {
    setRates(JSON.parse(localStorage.getItem("rates")));
    getRates();
  }, []);

  return { rates };
};
export default useCurrency;
