import React, { useState, useEffect } from "react";
import Header from "../../components/header/HeaderBlog";
import Footer from "../../components/footer/Footer";
import { Paper, Grid, TextField, Button, Typography } from "@material-ui/core";
import useAPI from "../../hooks/useAPI";
import Reservation from "../../Models/Reservation";
import { CDN_BASE_URL } from "../../config";
import ImageGallery from "react-image-gallery";
export default function Track() {
  const { response, loading, error, request } = useAPI(Reservation.checkReservationStatus);

  const [reservationCode, setReservationCode] = useState(null);

  const handleCheck = () => {
    request(reservationCode);
  };

  const renderMessage = (status) => {
    const response = {};
    switch (status) {
      case "404":
        response.color = "tomato";
        response.message = "Reservation not found.";
        break;
      case "400":
        response.color = "tomato";
        response.message = "Bad request.";
        break;

      case "500":
        response.color = "tomato";
        response.message = "Something is wrong. Try again later ... ";
        break;

      case "new":
        response.color = "black";
        response.message = "Your reservation is not yet reviewed";
        break;

      case "verified":
        response.color = "green";
        response.message = "Your reservation is verified.";
        break;

      case "cancelled":
        response.color = "orange";
        response.message = "Your reservations has been cancelled.";
        break;

      case "pending":
        response.color = "blue";
        response.message = "Ödeme Bekliyor";
        break;

      default:
        response.color = "gray";
        response.message = "Your reservation status is unknown.";
    }
    return (
      <Typography style={{ color: response.color }} variant="body1">
        {response.message}
      </Typography>
    );
  };

  const renderOrderDetails = ({ propertyTitle, entryDate, exitDate, images }) => {
    const sliders = images
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        url: encodeURI(CDN_BASE_URL + "/" + (item.webp ?? item.filename)),
      }));
    return (
      <div style={{ padding: 10, display: "flex", flexDirection: "column" }}>
        <ImageGallery
          items={sliders.map((item) => ({
            original: item.url,
            thumbnail: item.url,
          }))}
        />
        <div style={{ textAlign: "center" }}>
          <h3>{propertyTitle}</h3>
          <h5>
            {entryDate} / {exitDate}
          </h5>
        </div>
        <div></div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
          backgroundColor: "#F1F1F1",
          minHeight: window.innerHeight * 0.5,
        }}
      >
        <Grid xs={12} lg={4}>
          <Paper
            variant="outlined"
            style={{ display: "flex", flexDirection: "column", padding: 20 }}
          >
            <Typography variant="body1"> Lütfen rezervasyon kodunu giriniz </Typography>
            <TextField
              onChange={(event) => setReservationCode(event.target.value)}
              variant="outlined"
              type="text"
              fullWidth
              style={{ marginTop: 20 }}
              label="Tracking Code"
            />
            <Button
              onClick={handleCheck}
              disabled={!reservationCode || loading}
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: 20 }}
            >
              {loading ? "PLEASE WAIT" : "CHECK"}
            </Button>
          </Paper>
          {!loading && error && <Paper>{renderMessage(error.toString())}</Paper>}
          {!loading && !error && response && (
            <div style={{ marginTop: 10 }}>
              <Paper style={{ padding: 10 }}>{renderMessage(response.data.status)}</Paper>
              <Paper style={{ marginTop: 10 }}>{renderOrderDetails(response.data)}</Paper>
            </div>
          )}
        </Grid>
      </div>
      <Footer />
    </>
  );
}
