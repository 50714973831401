import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import KingBedIcon from "@material-ui/icons/KingBed";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BathtubIcon from "@material-ui/icons/Bathtub";
import { CDN_BASE_URL } from "../../config";
import makePrice from "../../helpers/makePrice";
import ImageGallery from "react-image-gallery";
import AppContext from "../../context/AppContext";

export default function HorizontalCard({ item }) {
  const { CM } = useContext(AppContext);
  const IconItem = ({ value, Icon }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon style={{ color: "#888", marginRight: 5, width: 30, height: 30 }} />
        <span style={{ color: "#4d4d4d", fontSize: 18 }}>
          {" "}
          <span style={{ color: "#888", fontSize: 12 }}>X</span> {value}
        </span>
      </div>
    );
  };
  return (
    <div
      style={{
        backgroundColor: "#FFF",
        cursor: "pointer",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <ImageGallery
            showPlayButton={false}
            lazyLoad={true}
            style={{ backgroundSize: "contain" }}
            items={(item.images ?? [])
              .sort((a, b) => a.order - b.order)
              .slice(0, 3)
              .map((item) => ({
                original: encodeURI(CDN_BASE_URL + "/" + (item.webp ?? item.filename)),
              }))}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onClick={() => window.open("/listingDetail/" + item.slug, "_blank")}
          >
            <div
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                paddingLeft: 10,
                paddingTop: 10,
              }}
            >
              <span>{item.general.propertyTitle}</span>
              <span style={{ marginTop: 5, fontSize: "0.9rem", color: "#4d4d4d" }}>
                {item.features.mainFeatures?.city || ""} •{" "}
                {item.features.mainFeatures?.district || ""}
              </span>
              <span style={{ marginTop: 5, fontSize: "0.9rem", color: "#4d4d4d" }}>
                {item.general.highlights || ""}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                paddingBlock: 10,
                marginTop: 10,
                backgroundColor: "#EFEFEF",
                borderTop: "1px solid #E9E9E9",
                borderBottom: "1px solid #E9E9E9",
              }}
            >
              <IconItem Icon={KingBedIcon} value={item.features.mainFeatures?.roomNumber} />
              <IconItem Icon={PeopleAltIcon} value={item.allowedPeople?.adults} />
              <IconItem Icon={BathtubIcon} value={item.features.mainFeatures?.bathroomNumber} />
            </div>
            {item.features.mainMenuCategory !== "Satılık Villalar" && (
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                {!item.totalPrice ? (
                  <>
                    <span style={{ fontSize: 12, color: "#888", marginBottom: 5 }}>
                      En Düşük Gecelik Fiyat
                    </span>
                    <b>
                      {`${makePrice(
                        item.prices.length > 0
                          ? item.prices.sort((a, b) => a.price - b.price)[0].price
                          : item.basePrice || 0,
                        item.general.xRate,
                        CM.rates
                      )}`}{" "}
                      TL
                    </b>
                  </>
                ) : (
                  <>
                    <span style={{ fontSize: 12, color: "#888", marginBottom: 5 }}>
                      Toplam Fiyat
                    </span>
                    <b>{`${makePrice(item.totalPrice, item.general.xRate, CM.rates)}`} TL</b>
                  </>
                )}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
