import React, { useState, useEffect } from "react";
import Header from "../../components/header/HeaderBlog";
import Footer from "../../components/footer/Footer";
import { Paper, Grid, TextField, Button, Typography } from "@material-ui/core";
import * as BlogModel from "../../Models/Blog.js";
import useAPI from "../../hooks/useAPI";
import BlogCard from "../../components/blogCard/BlogCard";
import { CDN_BASE_URL } from "../../config";
import queryString from "query-string";
import { Parser } from "html-to-react";

export default function SingleBlog(props) {
  const { error, loading, response, request } = useAPI(BlogModel.getSingleBlog);
  const blogListAPI = useAPI(BlogModel.getBlogs);
  const { id } = props.match.params;
  useEffect(() => {
    request(id);
    blogListAPI.request();
  }, []);

  const renderBlog = (response) => {
    const blog = response.data;
    return (
      <Grid container style={{ padding: 20, backgroundColor: "#F1F1F1" }} spacing={1}>
        <Grid item xs={12} lg={8}>
          <Paper style={{ padding: 20, borderRadius: 0 }} variant="outlined">
            <img
              src={CDN_BASE_URL + "/img/" + blog.images[0]}
              style={{ width: "100%", height: "50vh", objectFit: "cover" }}
            />

            <Typography variant="h6" style={{ marginTop: 20 }}>
              {blog.blogTitle}
            </Typography>
            <Typography variant="body1" style={{ textAlign: "justify", marginTop: 10 }}>
              {Parser().parse(blog.content)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            {blogListAPI.response
              ? blogListAPI.response.data.map((item) => {
                  return (
                    <Grid item xs={12} lg={6}>
                      <BlogCard
                        blog={{
                          id: item._id,
                          title: item.blogTitle,
                          description: item.content?.replace(/(<([^>]+)>)/gi, "").slice(0, 100),
                          image: item.images ? CDN_BASE_URL + "/img/" + item.images[0] : "",
                        }}
                      />
                    </Grid>
                  );
                })
              : ""}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Header />
      {loading && "Loading .... "}
      {error && "Error"}
      {response && renderBlog(response)}
      <Footer />
    </>
  );
}
