import React, { useEffect, useState } from "react";
import Header from "../../components/header/HeaderBlog";
import MainCarousel from "../../components/mainCrousel/MainCarousel";
import SearchBar from "../../components/searchBar/SearchBar";
import ShortTimeRent from "../../components/shortTimeRent/ShortTimeRent";
import Footer from "../../components/footer/Footer";
import BlogSlider from "../../components/blogSlider/BlogSlider";
import * as HomeModel from "../../Models/HomeModel";
import * as BlogModel from "../../Models/Blog";
import { Helmet } from "react-helmet";
import VillaView from "../../components/shared-components/VillaView";

import { CDN_BASE_URL } from "../../config";
import { Container } from "@material-ui/core";

const Home = () => {
  const [gaps, setGaps] = useState({});
  const [sliders, setSliders] = useState(null);
  const [info, setInfo] = useState(null);
  const [blogs, setBlogs] = useState(null);

  const getHomeInformation = async () => {
    const response = await HomeModel.getHomeInformation();
    if (response.ok) setInfo(response.data.data);
    console.log(info);
  };

  const getSliders = async () => {
    const response = await HomeModel.getSliders();
    console.log(response.data);
    if (response.ok) setSliders(response.data);
  };

  const getShortTimeRents = async () => {
    const response = await HomeModel.getAvailableGaps();
    console.log(response.data);
    if (response.ok) setGaps(response.data.data);
  };

  const getLatestBlogs = async () => {
    const response = await BlogModel.getBlogs(6);
    if (response.ok) setBlogs(response.data.data);
  };

  useEffect(() => {
    getHomeInformation();
    getSliders();
    getShortTimeRents();
    getLatestBlogs();
  }, []);

  return (
    <div style={{ backgroundColor: "#EEE" }}>
      {info && (
        <Helmet>
          <title>{info.seo.title}</title>
          <meta name="description" content={info.seo.description} />
          <meta name="keywords" content={info.seo.keywords} />
        </Helmet>
      )}
      <Header />
      {sliders && (
        <MainCarousel
          height={"50vh"}
          width={"100%"}
          sliders={sliders.map((item) => ({
            url: encodeURI(CDN_BASE_URL + "/img/" + item.filename),
          }))}
        />
      )}
      <div>
        <SearchBar />
      </div>
      {info && (
        <Container>
          <VillaView
            villas={info.discountedProps}
            title="İndirimli Villalar"
            link="/"
          />
          <div style={{ marginBottom: 10 }}>
            <VillaView
              villas={info.props}
              title="Öne Çıkan Villalar"
              link="/listings"
            />
          </div>
        </Container>
      )}
      {Object.keys(gaps).length > 0 && <ShortTimeRent gaps={gaps} />}
      {blogs && <BlogSlider blogs={blogs} />}
      <Footer />
    </div>
  );
};

export default Home;
