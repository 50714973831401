import React, { useState, useEffect, useContext } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import "./HeaderBlog.css";
import {
  Button,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { FaFacebook, FaInstagram, FaPhone, FaYoutube } from "react-icons/fa";
import AppContext from "../../context/AppContext";
import SearchBar from "./SearchBar";
const Header = () => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");

  const { DM } = useContext(AppContext);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSearchFormSubmit = (values) => {
    window.open("/listings?search=" + values["searchValue"], "_self");
  };

  const { categories } = useContext(AppContext);

  return (
    <div>
      <div className="topInfoWrapper" style={{ display: "flex" }}>
        <div
          className="socialMediaIconWrapper"
          style={{ flex: 1, paddingLeft: 20 }}
        >
          <div className="socialMediaIcons">
            <a href="https://www.youtube.com/channel/UCzBbLoxm0I7IBp1NSYn8feQ">
              <FaYoutube color="white" size="20" />
            </a>
          </div>
          <div className="socialMediaIcons">
            <a href="https://www.facebook.com/VillaBizdee/">
              <FaFacebook color="white" size="20" />
            </a>
          </div>
          <div className="socialMediaIcons">
            <a href="https://www.instagram.com/villabizde/">
              <FaInstagram color="white" size="20" />
            </a>
          </div>
        </div>
        <div style={{ paddingRight: 20 }}>
          <div
            style={{
              backgroundColor: "rgba(13, 13,13, 0.2)",
              borderRadius: 5,
              padding: 5,
            }}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="tel:05339249617"
            >
              <FaPhone style={{ marginRight: 10 }} />0 533 924 96 17
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingBlock: 10,
          paddingInline: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <a href="/">
            <img style={{ maxWidth: 200 }} src="/logo.png" />
          </a>
          <div style={{ textAlign: "right", marginTop: -10 }}>
            <span
              style={{
                color: "#0676C6",
                fontSize: "smaller",
                textTransform: "capitalize",
                fontStyle: "italic",
              }}
            >
              Kendi evinde hisset
            </span>
          </div>
        </div>
        {DM.isDesktop && (
          <div>
            <SearchBar handleSearchFormSubmit={handleSearchFormSubmit} />
          </div>
        )}
        {DM.isMobile && (
          <>
            <div>
              <div className={open ? "sidepanel" : "hiddenSidepanel"}>
                <Button onClick={() => setOpen(!open)} style={{ height: 56 }}>
                  ×
                </Button>
                <div style={{ textAlign: "left" }}>
                  <div>
                    <Button>Anasayfa</Button>
                  </div>
                  {/* Dropdown Menu */}
                  <div>
                    {categories?.map((item) => {
                      return (
                        <Accordion
                          square
                          expanded={expanded === item.mainCategory}
                          onChange={handleChange(item.mainCategory)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>{item.mainCategory}</Typography>
                          </AccordionSummary>
                          {item.categories.map((sc) => (
                            <AccordionDetails>
                              <div
                                style={{
                                  paddingBlock: 5,
                                  width: "100%",
                                  textAlign: "left",
                                }}
                              >
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: "#888",
                                  }}
                                  href={`/listings?category=${sc.category}`}
                                >
                                  {sc.category}
                                </a>
                              </div>
                            </AccordionDetails>
                          ))}
                        </Accordion>
                      );
                    })}
                  </div>
                  <div>
                    <Button className="mobileMenuButton" href="/blog">
                      Bölgeler
                    </Button>
                  </div>
                  <div>
                    <Button className="mobileMenuButton" href="/about">
                      Blog
                    </Button>
                  </div>
                  <div>
                    <Button className="mobileMenuButton" href="/about">
                      İletişim
                    </Button>
                  </div>
                </div>
              </div>
              <div className={open ? "hiddenButton" : "visibleButton"}>
                <Button style={{ height: 56 }} onClick={() => setOpen(!open)}>
                  ☰
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      {!DM.isDesktop && (
        <div style={{ paddingInline: 20 }}>
          <SearchBar handleSearchFormSubmit={handleSearchFormSubmit} />
        </div>
      )}
      <div>
        {DM.isDesktop && (
          <div className="wideScreenNavbar">
            <BottomNavigation
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              showLabels={true}
              style={{ backgroundColor: "transparent" }}
            >
              <a className="dropdownLinkMain" title="Anasayfa" href="/">
                Anasayfa
              </a>

              {categories?.map((item) => {
                return (
                  <div className="dropdown">
                    <a
                      className="dropdownLinkMain"
                      href={`/listings?mainCategory=${item.mainCategory}`}
                    >
                      {item.mainCategory}
                    </a>
                    <div className="dropdownContent">
                      {item.categories.map((sc) => (
                        <div className="dropdownContentBtn">
                          <a
                            className="dropdownLink"
                            href={`/listings?category=${sc.category}`}
                          >
                            {sc.category}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}

              <a className="dropdownLinkMain" href="/blog">
                Kısa Süreli Kiralıklar
              </a>

              <div className="dropdown">
                <a
                  onClick={(e) => e.preventDefault()}
                  className="dropdownLinkMain"
                  href={`/listings`}
                >
                  Villabizde
                </a>
                <div className="dropdownContent">
                  <div className="dropdownContentBtn">
                    <a className="dropdownLink" href={`/about`}>
                      Hakkımızda
                    </a>
                  </div>
                  <div className="dropdownContentBtn">
                    <a
                      className="dropdownLink"
                      href={`https://villabizde.net/blog/61891b1941f2d00573b3c3cb`}
                    >
                      Kiralama Sözleşmesi
                    </a>
                  </div>
                  <div className="dropdownContentBtn">
                    <a
                      className="dropdownLink"
                      href={`https://villabizde.net/blog/61891b9341f2d00573b3c3cd`}
                    >
                      Gizlilik Şartları
                    </a>
                  </div>
                  <div className="dropdownContentBtn">
                    <a
                      className="dropdownLink"
                      href={`https://villabizde.net/blog/61891b6a41f2d00573b3c3cc`}
                    >
                      İptal Şartları
                    </a>
                  </div>
                  <div className="dropdownContentBtn">
                    <a className="dropdownLink" href={`/bank-accounts`}>
                      Banka Hesap Bilgileri
                    </a>
                  </div>
                  <div className="dropdownContentBtn">
                    <a className="dropdownLink" href={`/contact`}>
                      İletişim
                    </a>
                  </div>
                  <div className="dropdownContentBtn">
                    <a className="dropdownLink" href={`/blog`}>
                      Blog
                    </a>
                  </div>
                </div>
              </div>

              <a
                className="dropdownLinkMain"
                style={{
                  marginLeft: "auto",
                }}
                href="/track"
              >
                Reservasyon Takibi
              </a>
            </BottomNavigation>
          </div>
        )}
      </div>
      <div
        style={{
          width: "35%",
          maxWidth: 120,
          display: DM.isMobile ? "none" : null,
        }}
      ></div>
    </div>
  );
};

export default Header;
