const cleanQuery = (values) => {
  if (!values || Object.keys(values).length == 0) return {};
  const newValue = {};
  Object.keys(values).map((key) => {
    if (values[key] && values[key] !== "") newValue[key] = values[key];
  });
  return newValue;
};

export default cleanQuery;
