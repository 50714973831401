import React, { useState, useEffect } from "react";
import Header from "../../components/header/HeaderBlog";
import Footer from "../../components/footer/Footer";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";

export default function About() {
  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <Paper variant="outlined" style={{ maxWidth: "50%" }}>
          <div
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "#E1E1E1",
              borderBottomStyle: "inset",
              padding: 10,
              backgroundColor: "#EEE",
              textAlign: "left",
            }}
          >
            <Typography variant="h6" style={{ fontWeight: "normal" }}>
              Villabizde
            </Typography>
          </div>
          <div style={{ padding: 20, textAlign: "justify" }}>
            <Typography variant="body1">
              Tatilinizi VillaBizde ayrıcalığıyla yaşayın. Şehrin gürültüsünden
              uzakta, doğa ile baş başa kalmaya ne dersiniz? Size en uygun
              villayı kiralamak için doğru yerdesiniz! Dünyanın dört bir
              yanından tatil için akın edilen Antalya'nın farklı bölgelerinde
              yer alan birçok farklı özelliklere sahip yüzlerce villa sizleri en
              iyi şekilde ağırlamak için bekliyor. Her bütçeye ve kişi sayısına
              uygun villalar; deniz manzaralı, doğanın kalbinde, lüks, konforlu
              v.s sahip oldukları özelliklere göre fiyatlandırılmaktadır. Siz de
              aradığınız özelliklere sahip villayı kiralamak için bizimle
              iletişime geçebilirsiniz.
            </Typography>
          </div>
        </Paper>
      </div>
      <Footer />
    </>
  );
}
