import React from "react";
import Home from "../pages/home/Home";
import Listings from "../pages/listings/Listings";
import ListingDetail from "../pages/listingDetail/ListingDetail";
import Track from "../pages/track/Track";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Order from "../pages/order/Order";
import Play from "../pages/play/Play";
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import Blog from "../pages/blog/Blog";
import SingleBlog from "../pages/blog/SingleBlog";

const Approuter = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/listings" component={Listings} />
          <Route path="/listingDetail/:slug" component={ListingDetail} />
          <Route path="/:slug/order" component={Order} />
          <Route path="/track" component={Track} />
          <Route path="/play" component={Play} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/blog/:id" component={SingleBlog} />
        </Switch>
      </Router>
    </div>
  );
};

export default Approuter;
