import apiClient from "../helpers/api";

const addNewReservation = (values) =>
  apiClient.post("/reservations", { ...values });

const checkReservationStatus = (code) =>
  apiClient.get("/reservations/check/" + code);

export default {
  addNewReservation,
  checkReservationStatus,
};
