import React, { useContext, useState } from "react";
import { Button, Typography, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "../listingDetailMakeReservation/ListingDetailMakeReservation.css";
import { Formik } from "formik";
import useAPI from "../../hooks/useAPI";
import { Listing } from "../../Models";
import DatePicker from "react-multi-date-picker";
import gregorian_tr from "../../config/gregorian_tr";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import "./custom.css";
import AppContext from "../../context/AppContext";
import makePrice from "../../helpers/makePrice";
import moment from "moment";
import TurkishMonth from "../shared-components/TurkishMonth";

const ListingDetailMakeResevation = ({ info }) => {
  // States
  const { loading, response, error, request } = useAPI(Listing.calculatePrice);
  const [selectedDates, setSelectedDates] = useState([]);
  const history = useHistory();
  const { CM } = useContext(AppContext);

  const handleSubmit = async (values) => {
    if (selectedDates.length !== 2) {
      alert("Select dates first");
      return;
    }

    const df = "YYYY-MM-DD";

    const startDate = moment(selectedDates[0].format(df));
    const endDate = moment(selectedDates[1].format(df));
    request(info._id, {
      start: startDate.format(df),
      end: endDate.format(df),
      adults: values.adults,
    });
  };

  const handleDateSelection = () => {
    if (selectedDates.length !== 2) return;
  };

  const ReservationDetailItem = ({ title, value }) => {
    return (
      <div
        style={{
          backgroundColor: "#F9F9F9",
          marginBottom: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 20,
        }}
      >
        <span>{title}</span>
        <span>{value}</span>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: "#073665",
          color: "white",
          paddingTop: 15,
          paddingBottom: 15,
          position: "sticky",
        }}
      >
        <Typography variant="body1">REZERVASYON YAP</Typography>
      </div>

      <Formik
        initialValues={{
          entryDate: "",
          exitDate: "",
          adults: 1,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, setFieldValue, values }) => {
          return (
            <div
              style={{
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <DatePicker
                  locale={gregorian_tr}
                  range
                  render={(value, openCalendar) => {
                    return (
                      <Button
                        onClick={openCalendar}
                        color="primary"
                        fullWidth
                        style={{ height: 56 }}
                        variant="outlined"
                      >
                        {selectedDates.length < 2 ? "Giriş Çıkış Tarihi" : selectedDates.join(", ")}
                      </Button>
                    );
                  }}
                  onChange={(values) => setSelectedDates(values)}
                  onClose={handleDateSelection}
                  value={selectedDates}
                  mapDays={({ date }) => {
                    let props = {};
                    // if it's expired (past dates)
                    if (date < moment()) {
                      return {
                        id: "disabled-expired",
                        disabled: true,
                      };
                    } else if (info.reservations.includes(date.format("YYYY-MM-DD"))) {
                      const foundDate = info.reservationList.find(
                        (x) => x.date == date.format("YYYY-MM-DD")
                      );
                      const type = foundDate.type;
                      const status = foundDate.status;
                      if (type == "entryDate") {
                        return {
                          id: "entry",
                        };
                      }
                      if (type == "exitDate")
                        return {
                          id: "exit",
                        };
                      if (type == "normal")
                        if (status === "pending")
                          return {
                            id: "disabled-pending",
                            disabled: true,
                          };
                      if (status === "verified" || status === "whatsapp")
                        return {
                          id: "disabled-verified",
                          disabled: true,
                        };
                    }

                    return props;
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 15,
                  }}
                >
                  <Button
                    style={{
                      border: "1px solid #e1e1e1",
                      borderRadius: 3,
                      height: 56,
                      width: 56,
                      marginRight: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() =>
                      setFieldValue("adults", values["adults"] > 1 ? values["adults"] - 1 : 1)
                    }
                  >
                    <RemoveCircle style={{ color: "#FF7E00" }} />
                  </Button>
                  <div style={{ flex: 1 }}>
                    <TextField
                      label="Kişi Sayısı"
                      inputProps={{ style: { textAlign: "center" } }}
                      value={values["adults"] || 1}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ style: { textAlign: "center" } }}
                    />
                  </div>
                  <Button
                    style={{
                      border: "1px solid #e1e1e1",
                      borderRadius: 3,
                      height: 56,
                      width: 56,
                      marginLeft: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => setFieldValue("adults", values["adults"] + 1)}
                  >
                    <AddCircle style={{ color: "#FF7E00" }} />
                  </Button>
                </div>
              </div>
              <p
                style={{
                  color: "#4D4D4D",
                  textAlign: "center",
                }}
              >
                <a href="https://villabizde.net/blog/61891b1941f2d00573b3c3cb" target="_blank">
                  Kiralama sözleşmesi
                </a>
              </p>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                onClick={handleSubmit}
              >
                Fiyat Hesapla
              </Button>

              {loading && <p>Calculating Price ... </p>}
              {!loading && error && (
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 15,
                    backgroundColor: "#EEE",
                    borderRadius: 5,
                  }}
                >
                  <Typography
                    style={{ color: "tomato", textTransform: "uppercase" }}
                    variant="body2"
                  >
                    {error}
                  </Typography>
                </div>
              )}
              {!loading && response && (
                <>
                  <div style={{ marginTop: 10 }}>
                    <ReservationDetailItem
                      title="Giriş Tarihi"
                      value={<TurkishMonth date={response.data.start} />}
                    />

                    <ReservationDetailItem
                      title="Çıkış Tarihi"
                      value={<TurkishMonth date={response.data.end} />}
                    />

                    <ReservationDetailItem
                      title="Toplam Fiyat"
                      value={`${makePrice(
                        response.data.totalPrice,
                        info.general.xRate,
                        CM.rates
                      )} TL`}
                    />

                    <ReservationDetailItem
                      title="Ön Ödeme"
                      value={`${makePrice(
                        info.depositRate
                          ? (response.data.totalPrice * info.depositRate) / 100
                          : response.data.totalPrice,
                        info.general.xRate,
                        CM.rates
                      )} TL`}
                    />
                  </div>

                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    style={{
                      marginTop: 10,
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/${info.slug}/order`,
                        info: {
                          propertyCode: info.propertyCode,
                          entryDate: selectedDates[0].format("YYYY-MM-DD"),
                          exitDate: selectedDates[1].format("YYYY-MM-DD"),
                          adults: values["adults"],
                          nights: response.data.selectedPrices.length,
                          total: response.data.discountedPrice,
                        },
                      })
                    }
                  >
                    Rezervasyonu Tamamla
                  </Button>
                </>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ListingDetailMakeResevation;
