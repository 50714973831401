import React from "react";
import GoogleMapComp from "./GoogleMapComp";
import { Button, Grid, Typography } from "@material-ui/core";

export default function TabMap({ map, propInfo }) {
  return (
    <div>
      {map && (
        <>
          <GoogleMapComp
            isMarkerShown={map}
            lat={map.latitude}
            lng={map.longitude}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDvqTJw96JKFsW7aPzD7mtg83KZg8t1OR0&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
          <a
            target="_blank"
            href={`https://www.google.com/maps/dir/${map.latitude},${map.longitude}`}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: 10 }}
            >
              Show LArge Map
            </Button>
          </a>
        </>
      )}
      <Grid container spacing={1} style={{ paddingTop: 10 }}>
        {propInfo.features.distances.map((item) => {
          if (item.checked && item.distance)
            return (
              <Grid item xs={12}>
                <div
                  style={{
                    backgroundColor: "#F1F1F1",
                    padding: 5,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#E0E0E0",
                      padding: 10,
                      marginRight: 10,
                      width: 60,
                    }}
                  >
                    <Typography
                      style={{
                        textTransform: "uppercase",
                        fontSize: 13,
                      }}
                      variant="subtitle2"
                    >
                      {item.distance}
                    </Typography>
                  </div>
                  <Typography variant="body1">{item.text} </Typography>
                  {item.description && (
                    <Typography
                      variant="body2"
                      style={{ marginLeft: 10, color: "#777" }}
                    >
                      {item.description || ""}
                    </Typography>
                  )}
                </div>
              </Grid>
            );
        })}
      </Grid>
    </div>
  );
}
