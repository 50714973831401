import { useState } from "react";
const useAPI = (apiFunc) => {
  // States
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);

  // Function caller
  const request = async (...args) => {
    setLoading(true);
    const result = await apiFunc(...args);
    setLoading(false);
    if (!result.ok) return setError("Internal Server Error");
    if (result.ok && result.data.status !== 200)
      return setError(result.data.message || result.data.status);
    setResponse(result.data);
    setError(false);
    return result;
  };

  const clear = () => {
    setLoading(false);
    setResponse(null);
    setError(false);
  };

  return { loading, error, response, request, clear };
};

export default useAPI;
