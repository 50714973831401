import React from "react";
import "../listingProperty/ListingProperty.css";
import PropertyCard from "../propertyCard/PropertyCard";
import { Container, Grid } from "@material-ui/core";
import HorizontalCard from "../HorizontalCard";

const ListingProperty = ({ items }) => {
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={12}>
          <HorizontalCard item={item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ListingProperty;
