import React from "react";
import { PhoneAndroid } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
export default function ContactElement({ title, icon, value }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        marginTop: 10,
        backgroundColor: "#EEE",
        borderRadius: 3,
      }}
    >
      {icon}
      <Typography variant="body1" style={{ marginLeft: 10 }}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        style={{
          marginLeft: 10,
          backgroundColor: "#E0E0E0",
          padding: 5,
          borderRadius: 5,
        }}
      >
        <a
          style={{ textDecoration: "none", color: "dodgerblue", fontSize: 16 }}
          href="call:00905516833833"
        >
          {value}
        </a>
      </Typography>
    </div>
  );
}
