import React from "react";
import "./CustomCalendar.css";
import dra from "date-range-array";
import moment from "moment";
import makeCalendar from "../../helpers/makeCalendar";
import { monthsENTR } from "../../DATA";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import MonthBox from "../appcalendar/MonthBox";

const calendar = makeCalendar();
export default function TabPrices({ reservationList, prices }) {
  console.log("prices", prices);
  console.log("reservation list", reservationList);
  const TurkishMonth = ({ date }) => {
    const dateSplitted = date.split("-");
    return (
      <span style={{ textTransform: "capitalize" }}>
        <b>
          {`${dateSplitted[0]} ${Object.values(monthsENTR)[parseInt(dateSplitted[1]) - 1]} ${
            dateSplitted[2]
          }`}
        </b>
      </span>
    );
  };

  let priceList = {};
  if (prices) {
    prices.map((item) => {
      dra(item.range.start.slice(0, 10), item.range.end.slice(0, 10)).map((date) => {
        if (!Object.keys(priceList).includes(date)) priceList[date] = item.price;
      });
    });
  }
  return (
    <div>
      {prices.map((item) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#EEE",
              paddingLeft: 10,
              marginBottom: 10,
            }}
          >
            <div>
              <TurkishMonth date={moment(item.range.start).format("DD-MM-YYYY")} />
              {` - `}
              <TurkishMonth date={moment(item.range.end).format("DD-MM-YYYY")} />
            </div>
            <div
              style={{
                display: "flex",
                color: "white",
              }}
            >
              <div style={{ backgroundColor: "#FF7E00", padding: 10, width: 80 }}>
                <b>₺{item.price.toLocaleString("tr")}</b>
                <br />
                <span style={{ color: "#EEE" }}>Gecelik</span>
              </div>
              <div style={{ backgroundColor: "#3F51B5", padding: 10, width: 80 }}>
                <b>
                  ₺{(item.price * 7).toLocaleString("tr")} <br />
                </b>
                <span style={{ color: "#EEE" }}>Haftalık</span>
              </div>
            </div>
          </div>
        );
      })}
      <div style={{ border: "1px solid #CECECE", padding: 10, marginBottom: 10 }}>
        İncelemiş olduğunuz villanın fiyatlarını villa sahibi belirlemektedir.
      </div>
      <Swiper
        spaceBetween={10}
        modules={[Pagination, Navigation]}
        slidesPerView={2}
        navigation={true}
      >
        {Object.keys(calendar)
          .filter((month) => month > moment().month())
          .map((month) => {
            return (
              <SwiperSlide item xs={12} lg={4}>
                <MonthBox
                  monthName={Object.values(monthsENTR)[month - 1]}
                  monthData={calendar[month]}
                  reservationList={reservationList}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
