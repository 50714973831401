import React, { useContext } from "react";
import { FaEnvelope, FaLocationArrow, FaPhone } from "react-icons/fa";
import "./Footer.css";
import { Grid, Typography } from "@material-ui/core";
import AppContext from "../../context/AppContext";
const Footer = () => {
  const { categories } = useContext(AppContext);
  return (
    <div className="footerContainer" style={{ marginTop: 20 }}>
      <div className="generalFooterWrapper">
        <Grid container className="topFooterWrapper">
          <Grid item xs={12} lg={4} className="topFooterContent">
            <FaPhone />
            <p>
              <strong>villabizde Danışma Hattı</strong> <br />
              <a style={{ textDecoration: "none", color: "white" }} href="tel:05339249617">
                +90 (533) 924 96 17
              </a>
              <br />
              <a style={{ textDecoration: "none", color: "white" }} href="tel:05334869617">
                +90 (533) 486 96 17
              </a>
            </p>
          </Grid>
          <Grid item xs={12} lg={4} className="topFooterContent">
            <FaLocationArrow />
            <p>
              <strong>Merkez Ofis:</strong> <br /> Kalkan Mah. Cumhuriyet Cad.
              <br /> 67/1 Kaş/Antalya
            </p>
          </Grid>
          <Grid item xs={12} lg={4} className="topFooterContent">
            <FaEnvelope />
            <p>
              <strong>E-Posta</strong> <br />
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="mailto:info@villabizde.net"
              >
                info@villabizde.net
              </a>
            </p>
          </Grid>
        </Grid>

        <div className="middleFooterWrapper">
          <p style={{ textAlign: "center" }}>
            <strong>VILLABIZDE</strong>
          </p>
          <Typography variant="body1" style={{ textAlign: "center" }}>
            SARIKAYA YATIRIM İNŞAAT EMLAK TURİZM TİCARET VE SAN.LTD.ŞTİ
          </Typography>
          <Typography variant="h5" style={{ textAlign: "center", marginTop: 20 }}>
            Yardım / Destek
          </Typography>
          <Grid container alignItems="center" justifyContent="center" style={{ padding: 20 }}>
            {["Gizlilik Şartları", "İptal Şartları", "Hakkımızda", "Banka Hesapları"].map(
              (item) => {
                return (
                  <Grid item xs={3} className="footerLinkContainer">
                    <a href="/" className="footerLink">
                      {item}
                    </a>
                  </Grid>
                );
              }
            )}
          </Grid>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            Kategoriler
          </Typography>

          <Grid container alignItems="center" justifyContent="center" style={{ padding: 20 }}>
            {categories
              ? categories.map((item) => {
                  return (
                    <Grid item xs={2} className="footerLinkContainer">
                      <a class="footerLink" href={`/listings?mainCategory=${item.mainCategory}`}>
                        {item.mainCategory}
                      </a>
                    </Grid>
                  );
                })
              : ""}
          </Grid>
        </div>
      </div>
      <div style={{ padding: 20 }}>
        <Typography variant="body1" style={{ color: "#4D4D4D" }}>
          Copyright &copy; 2016-2022 Villabizde Turizm Ltd.Şti. - İzinsiz Hiç Bir içerik
          Kullanılamaz. Tüm Hakları Saklıdır.
        </Typography>
        <Typography
          style={{
            marginTop: 10,
            textTransform: "uppercase",
            letterSpacing: 1.1,
            color: "#3D3D3D",
          }}
          variant="body2"
        >
          Developed with {<span style={{ color: "tomato", fontSize: 24 }}> ♥ </span>}by{" "}
          <a
            style={{ color: "#3f51b5", textDecoration: "none" }}
            href="https://divsoft.com.tr"
            target="_blank"
            rel="noreferrer"
          >
            DIVSOFT
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
