import { Formik } from "formik";
import { TextField, Button } from "@material-ui/core";
import { FaSearch } from "react-icons/fa";

export default function SearchBar({ handleSearchFormSubmit }) {
  return (
    <Formik onSubmit={handleSearchFormSubmit} initialValues={{ searchValue: "" }}>
      {({ handleChange, handleSubmit, values }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            onChange={handleChange("searchValue")}
            value={values["searchValue"]}
            variant="outlined"
            name="searchValue"
            fullWidth
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSubmit();
            }}
          />
          <Button
            disabled={!values["searchValue"]}
            variant="outlined"
            onClick={handleSubmit}
            style={{ height: 56, marginLeft: 10 }}
          >
            <FaSearch />
          </Button>
        </div>
      )}
    </Formik>
  );
}
