import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import queryString from "query-string";

export default function TabYoutube({ videoURL }) {
  if (!videoURL) return null;
  const { v: videoID } = queryString.parse(videoURL.split("?")[1]);
  return <LiteYouTubeEmbed id={videoID} />;
}
