import React from "react";
import "../shortTimeRent/ShortTimeRent.css";
import { Button, Grid, Typography } from "@material-ui/core";
import { monthsENTR } from "../../DATA";
const ShortTimeRent = ({ gaps }) => {
  if (Object.keys(gaps).length === 0) return <></>;
  return (
    <div style={{ backgroundColor: "#EEE" }}>
      <div style={{ backgroundColor: "white", padding: 20 }}>
        <Typography variant="h4" style={{ fontSize: 20 }}>
          Kısa Süreli Kiralıklar
        </Typography>
      </div>
      <div style={{ padding: 10 }}>
        <Grid container spacing={2}>
          {Object.keys(gaps).map((month) => {
            return (
              <Grid item xs={6} lg={3}>
                <div className="shortTimeList" style={{ borderRadius: 3 }}>
                  <h3>{monthsENTR[month].toUpperCase()}</h3>
                  <hr />
                  {[1, 2, 3, 4, 5].map((index) => {
                    if (gaps[month][index.toString()])
                      return (
                        <li>
                          <a href={"/listings?gapTimes=" + month + "." + index}>
                            {index} Günlük Evler
                          </a>
                          <span>{gaps[month][index.toString()]}</span>
                        </li>
                      );
                  })}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div style={{ padding: 20 }}>
        <Button variant="contained" color="primary">
          Tümünü Gör
        </Button>
      </div>
    </div>
  );
};

export default ShortTimeRent;
