import React, { useState, useEffect } from "react";
import Header from "../../components/header/HeaderBlog";
import Footer from "../../components/footer/Footer";
import { Paper, Grid, TextField, Button, Typography } from "@material-ui/core";
import * as BlogModel from "../../Models/Blog.js";
import useAPI from "../../hooks/useAPI";
import BlogCard from "../../components/blogCard/BlogCard";
import { CDN_BASE_URL } from "../../config";
export default function Blog() {
  const { error, loading, response, request } = useAPI(BlogModel.getBlogs);
  useEffect(() => {
    request();
  }, []);
  return (
    <>
      <Header />
      {loading && "Loading  .... "}
      {error && { error }}
      <Grid container spacing={2} style={{ padding: 20 }}>
        {response
          ? response.data.map((item) => {
              return (
                <Grid item xs={6} md={6} lg={3}>
                  <BlogCard
                    blog={{
                      id: item._id,
                      title: item.blogTitle,
                      image:
                        item.images.length > 0
                          ? CDN_BASE_URL + "/img/" + item.images[0]
                          : "",
                      description: item.content
                        ?.replace(/(<([^>]+)>)/gi, "")
                        .slice(0, 100),
                    }}
                  />
                </Grid>
              );
            })
          : ""}
      </Grid>

      <Footer />
    </>
  );
}
