import React, { useState } from "react";
import { TextField, Button, Paper, makeStyles, Typography, Divider } from "@material-ui/core";
import { Formik } from "formik";
const useStyles = makeStyles({
  inputField: {
    marginTop: 10,
  },
});
const OrderForm = ({ onFormSubmit, response, error, loading, nights, fixedDeposit }) => {
  const [paymentMethod, setPaymentMethod] = useState("Kredit Kart");
  const classes = useStyles();

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          TCNumber: "",
        }}
        onSubmit={onFormSubmit}
      >
        {({ handleChange, handleSubmit, values }) => {
          return (
            <Paper style={{ padding: 20, borderRadius: 3 }} variant="outlined">
              <TextField
                fullWidth
                placeholder="Ad Soyad"
                variant="outlined"
                type="text"
                onChange={handleChange("name")}
              />
              <TextField
                fullWidth
                type="email"
                variant="outlined"
                placeholder="Email"
                onChange={handleChange("email")}
                className={classes.inputField}
              />
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Telefon Numarası"
                onChange={handleChange("phone")}
                className={classes.inputField}
              />
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                placeholder="TC Numarası"
                onChange={handleChange("TCNumber")}
                className={classes.inputField}
              />
              <div style={{ textAlign: "left", marginTop: 20 }}>
                <Typography variant="h6" style={{ fontSize: 16 }} gutterBottom>
                  Hasar Depozitosu
                </Typography>
                <Typography variant="subtitle1" style={{ color: "#888" }}>
                  Villaya girişte {fixedDeposit} TL nakit hasar depozitosu alınmaktadır. Villada
                  hasar, zayi, kırık vb. gibi durumlar oluşmaması durumunda bu bedel çıkış gününde
                  iade edilmektedir.
                </Typography>
                <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />
                <Typography variant="h6" style={{ fontSize: 16 }} gutterBottom>
                  Ücrete dahil olanlar
                </Typography>
                <Typography variant="subtitle1" style={{ color: "#888" }}>
                  {nights} Gece Konaklama Elektrik , Su ve Tüpgaz Kullanımı - Wi-Fi - Günlük Havuz
                  ve Bahçe Bakımı
                </Typography>
                {/* <Divider
                  variant="middle"
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
                <Typography variant="h6" style={{ fontSize: 16 }}>
                  Ödeme yöntemi
                </Typography>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justiftContent="center"
                  style={{ marginTop: 5 }}
                >
                  {["Kredit Kart", "Banka Havalesi", "Western Union"].map(
                    (item) => {
                      return (
                        <Grid item xs>
                          <Paper
                            variant="outlined"
                            onClick={() => setPaymentMethod(item)}
                            style={{
                              cursor: "pointer",
                              paddingLeft: 10,
                              paddingRight: 10,
                              textAlign: "center",
                              backgroundColor:
                                paymentMethod == item ? "dodgerblue" : "white",
                              color: paymentMethod == item ? "white" : "black",
                            }}
                          >
                            <p>{item}</p>
                          </Paper>
                        </Grid>
                      );
                    }
                  )}
                </Grid> */}
                {/* <Divider
                  variant="middle"
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
                <FormControlLabel
                  control={<Checkbox name="checkedA" />}
                  label="I agree with terms and conditions"
                /> */}
              </div>
              <br />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className="reservationButton"
                style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                onClick={handleSubmit}
                disabled={response ? (response.status == 200 ? true : false) : false}
              >
                Rezervasyon Talebi Gönder
              </Button>
              {loading && <p>Lütfen bekleyin .... </p>}
              {!loading && error && (
                <div
                  style={{
                    marginTop: 10,
                    backgroundColor: "#888",
                    borderRadius: 5,
                  }}
                >
                  <Typography variant="body2">{error}</Typography>
                </div>
              )}
              {!loading && !error && response && <p>Rezervasyon talebiniz alınmıştır</p>}
            </Paper>
          );
        }}
      </Formik>
    </>
  );
};

export default OrderForm;
