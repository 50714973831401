export const filterCitiesList = [
  { text: "Antalya", value: 7 },
  { text: "Istanbul", value: 34 },
  { text: "Izmir", value: 35 },
  { text: "Muğla", value: 48 },
];

export const sampleListings = [
  {
    title: "Good Villa",
    subtitle: "Lara Mahallesi",
    details: "10.000 USD | Residence + Pool",
  },
  {
    title: "Beautifull Villa",
    subtitle: "Guzeloba Mahallesi",
    details: "20.000 USD | Security + Gym",
  },
  { title: "Bad Villa", subtitle: "Kepez", details: "Free" },
  { title: "Old Villa", subtitle: "Fener", details: "5.000 USD" },
];

export const defaultCategories = [
  { mainCategory: "VIllas for Sale", categories: ["a", "b", "c"] },
  { mainCategory: "Villas for Rent", categories: ["d", "e", "f"] },
];

export const monthsENTR = {
  January: "ocak",
  February: "şubat",
  March: "mart",
  April: "nisan",
  May: "Mayıs",
  June: "haziran",
  July: "temmuz",
  August: "ağustos",
  September: "eylül",
  October: "ekim",
  November: "kasım",
  December: "aralık",
};
