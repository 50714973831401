import { monthsENTR } from "../../DATA";
export default function TurkishMonth({ date }) {
  const dateSplitted = date.split("-");
  return (
    <span style={{ textTransform: "capitalize" }}>
      {`${dateSplitted[0]} ${Object.values(monthsENTR)[parseInt(dateSplitted[1]) - 1]} ${
        dateSplitted[2]
      }`}
    </span>
  );
}
