import React from "react";
import { Grid, Typography } from "@material-ui/core";
import BlogCard from "../blogCard/BlogCard";
import { CDN_BASE_URL } from "../../config";
export default function BlogSlider({ blogs }) {
  return (
    <>
      <div style={{ backgroundColor: "white", padding: 20, marginTop: 20 }}>
        <Typography variant="h4" style={{ fontSize: 20 }}>
          Blog
        </Typography>
      </div>
      <Grid container style={{ padding: 10 }} spacing={2} xs={12}>
        {blogs.slice(0, 4).map((item) => {
          return (
            <Grid item xs={12} md={6} lg={3}>
              <BlogCard
                blog={{
                  id: item._id,
                  title: item.blogTitle,
                  image:
                    item.images.length > 0
                      ? CDN_BASE_URL + "/img/" + item.images[0]
                      : "",
                  description: item.content
                    ?.replace(/(<([^>]+)>)/gi, "")
                    .slice(0, 100),
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
