import React from "react";
import { Grid, Typography } from "@material-ui/core";
export default function MiniTextComponent({ title, value }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div>
        <Typography noWrap variant="body2">
          {title}
        </Typography>
      </div>
      <div>
        <Typography
          style={{ textTransform: "capitalize", color: "#4D4D4D" }}
          noWrap
          variant="body2"
        >
          {value}
        </Typography>
      </div>
    </div>
  );
}
