import { useState, useEffect } from "react";
export default function useDimension() {
  const [width, setWidth] = useState(window.innerWidth);

  const updateWindowDimensions = () => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  return { isMobile: width <= 1200, isDesktop: width > 1200 };
}
