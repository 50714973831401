import "./App.css";
import Approuter from "./router/Approuter";
import AppContext from "./context/AppContext";
import react, { useEffect, useState } from "react";
import * as HomeModel from "./Models/HomeModel";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import useCurrency from "./hooks/useCurrency";
import useDimension from "./hooks/useDimension";
import TagManager from "react-gtm-module";
function App() {
  const [categories, setCategories] = useState([]);
  const CM = useCurrency();
  const DM = useDimension();
  const getCategories = async () => {
    const response = await HomeModel.getCategories();
    if (response.ok && response.data) setCategories(response.data);
  };
  useEffect(() => {
    getCategories();
  }, []);

  // use default theme
  // const theme = createTheme();

  // Or Create your Own theme:
  const theme = createTheme({
    palette: {
      primary: {
        main: "#073665",
      },
      secondary: {
        main: "#E33E7F",
      },
    },
  });
  TagManager.initialize({
    gtmId: "GTM-N6HR4GK",
  });
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <AppContext.Provider value={{ categories, CM, DM }}>
          <Approuter />
        </AppContext.Provider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
