import React, { useContext } from "react";
import { Typography, Chip } from "@material-ui/core";
import CardFooter from "./CardFooter.js";
import KingBedIcon from "@material-ui/icons/KingBed";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BathtubIcon from "@material-ui/icons/Bathtub";
import { CDN_BASE_URL } from "../../config";
import LabelIcon from "@material-ui/icons/Label";
import ImageGallery from "react-image-gallery";
import makePrice from "../../helpers/makePrice.js";
import AppContext from "../../context/AppContext.js";

export default function PropertyCard({ item }) {
  const { CM } = useContext(AppContext);
  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => window.open(`/listingDetail/${item.slug}`, "_new")}
        >
          <div>
            <Typography style={{ color: "#073665" }}>{item.general.propertyTitle}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2" style={{ color: "#4E4E4E", fontSize: 12 }}>
              {item.features.mainFeatures?.city || ""} •{item.features.mainFeatures?.district || ""}
            </Typography>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            fontSize: ".8rem",
            color: "#4d4d4d",
            fontWeight: "bold",
          }}
        >
          {item.general.highlights || ""}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          height: "30vh",
          width: "100%",
          overflow: "hidden",
          backgroundColor: "#FFF",
          display: "flex",
        }}
      >
        <ImageGallery
          showPlayButton={false}
          lazyLoad={true}
          style={{ backgroundSize: "contain", flex: 1 }}
          items={(item.images ?? [])
            .sort((a, b) => a.order - b.order)
            .slice(0, 3)
            .map((item) => ({
              original: CDN_BASE_URL + "/" + (item.webp ?? item.filename),
            }))}
        />
      </div>
      <div>
        <Chip
          style={{ borderRadius: 5, marginBottom: 10 }}
          avatar={<LabelIcon />}
          label={
            <Typography variant="body1" style={{ fontSize: 12 }}>
              From{" "}
              <b>
                {`${makePrice(
                  item.prices.length > 0
                    ? item.prices.sort((a, b) => a.price - b.price)[0].price
                    : item.basePrice || 0,
                  item.general.xRate,
                  CM.rates
                )}`}
                TL
              </b>{" "}
              / Night
            </Typography>
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          aligItems: "center",
          justifyContent: "space-around",
          borderTop: "1px solid #EEE",
          padding: 10,
        }}
      >
        <CardFooter
          icon={<PeopleAltIcon fontSize="medium" style={{ color: "#66C232" }} />}
          title={item.allowedPeople?.adults}
        />
        <CardFooter
          icon={<KingBedIcon fontSize="medium" style={{ color: "#66C232" }} />}
          title={item.features.mainFeatures?.bathroomNumber || ""}
        />
        <CardFooter
          icon={<BathtubIcon fontSize="medium" style={{ color: "#66C232" }} />}
          title={item.features.mainFeatures?.roomNumber || ""}
        />
      </div>
    </div>
  );
}
