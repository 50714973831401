import React, { useState, useEffect } from "react";
import Header from "../../components/header/HeaderBlog";
import Footer from "../../components/footer/Footer";
import { Paper, Grid, TextField, Button, Typography } from "@material-ui/core";
import { EmailOutlined, LocationCity, PhoneAndroid } from "@material-ui/icons";
import ContactElement from "./ContactElement.js";
import GoogleMapComp from "../../components/listingDetailTab/GoogleMapComp";
export default function Contact() {
  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <Paper variant="outlined" style={{ flex: 1, maxWidth: "50%" }}>
          <div
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "#E1E1E1",
              borderBottomStyle: "inset",
              backgroundColor: "#EEE",
              textAlign: "left",
              padding: 10,
            }}
          >
            <Typography variant="h6" style={{ fontWeight: "normal" }}>
              Contact Us
            </Typography>
          </div>
          <div style={{ padding: 20, textAlign: "justify" }}>
            Tatilinizi VillaBizde ayrıcalığıyla yaşayın. Şehrin gürültüsünden
            uzakta, doğa ile baş başa kalmaya ne dersiniz? Size en uygun villayı
            kiralamak için doğru yerdesiniz! Dünyanın dört bir yanından tatil
            için akın edilen Antalya'nın farklı bölgelerinde yer alan birçok
            farklı özelliklere sahip yüzlerce villa sizleri en iyi şekilde
            ağırlamak için bekliyor. Her bütçeye ve kişi sayısına uygun
            villalar; deniz manzaralı, doğanın kalbinde, lüks, konforlu v.s
            sahip oldukları özelliklere göre fiyatlandırılmaktadır. Siz de
            aradığınız özelliklere sahip villayı kiralamak için bizimle
            iletişime geçebilirsiniz.
            <div style={{ marginTop: 20 }}>
              <ContactElement
                title="Phone Number"
                value="+90 (533) 9249617"
                icon={<PhoneAndroid />}
              />
              <ContactElement
                title="Email Address"
                value="info@villabizde.net"
                icon={<EmailOutlined />}
              />
              <ContactElement
                title="Office"
                value="Kalkan Mah. Cumhuriyet Cad.
               67/1 Kaş/Antalya"
                icon={<LocationCity />}
              />
            </div>
          </div>
          <div style={{ padding: 10 }}>
            <GoogleMapComp
              isMarkerShown
              lat={0.125}
              lng={0.125}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDvqTJw96JKFsW7aPzD7mtg83KZg8t1OR0&libraries=places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </Paper>
      </div>
      <Footer />
    </>
  );
}
