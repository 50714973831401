import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Paper, Grid, Divider, Select, MenuItem } from "@material-ui/core";
import ChevronRightTwoToneIcon from "@material-ui/icons/ChevronRightTwoTone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CDN_BASE_URL } from "../../config";
import makePrice from "../../helpers/makePrice";
import AppContext from "../../context/AppContext";
import translate from "../../helpers/translate";

const useStyles = makeStyles({
  root: {
    margin: "auto",
  },
  media: {
    height: 140,
  },
});

export default function OrderFormCard({ orderInfo, propertyInfo, onAdditionalInfo }) {
  const classes = useStyles();

  const services = {};
  propertyInfo.services?.map((serviceObj) => {
    services[serviceObj.title] = {
      price: serviceObj.price || 0,
      qty: 1,
    };
  });
  console.log(services);
  const [selectedServices, setSelectedServices] = useState(services);
  let totalServicePrices = 0;
  Object.values(services).map(({ price, qty }) => (totalServicePrices += price * qty));
  const [total, setTotal] = useState({
    property: orderInfo.total,
    services: totalServicePrices,
  });
  const { CM } = useContext(AppContext);

  const handleServiceChange = (event, code) => {
    const qty = event.target.value;
    const temp = { ...selectedServices };
    temp[code]["qty"] = qty;
    setSelectedServices(temp);
    let servicesTotal = 0;
    Object.keys(temp).map((code) => {
      servicesTotal += temp[code]["price"] * temp[code]["qty"];
    });
    setTotal((x) => ({ ...x, services: servicesTotal }));
    onAdditionalInfo({ services: selectedServices });
  };

  return (
    <Paper variant="outlined">
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={
            propertyInfo.images?.length > 0
              ? CDN_BASE_URL + "/img/" + propertyInfo.images?.sort((a, b) => a.order - b.order)[0].filename
              : ""
          }
          title="Luxurious Villa"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {propertyInfo.general.propertyTitle}
          </Typography>
          <Typography gutterButtom variant="subtitle1" component="p">
            {propertyInfo.features.mainFeatures?.city || ""}•{propertyInfo.features.mainFeatures?.district || ""}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {propertyInfo.general.highlights || ""}
          </Typography>
          <Divider variant="middle" style={{ marginTop: 10 }} />
          <Grid container spacing={1} style={{ marginTop: 10 }} alignItems="center" justifyContent="center">
            <Grid item xs={5}>
              <Paper style={{ padding: 10 }}>{orderInfo.entryDate}</Paper>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ChevronRightTwoToneIcon />
            </Grid>
            <Grid item xs={5}>
              <Paper style={{ padding: 10 }}>{orderInfo.exitDate}</Paper>
            </Grid>
          </Grid>
          <TableContainer style={{ marginTop: 10 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <b>{orderInfo.nights} Geceler </b>
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">{makePrice(orderInfo.total, propertyInfo.general.xRate, CM.rates)} TL</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <b>{orderInfo.rooms} Odalar </b>
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">-</TableCell>
                </TableRow>

                {/* SERVICES */}
                {propertyInfo.services?.map((s) => {
                  return (
                    <TableRow>
                      <TableCell align="left">
                        <b>{translate(s.title)}</b>
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          onChange={(event) => handleServiceChange(event, s.title)}
                          variant="outlined"
                          value={selectedServices[s.title]["qty"]}
                        >
                          {[...Array(orderInfo.nights).keys()].map((index) => {
                            return <MenuItem value={index + 1}>{index + 1}</MenuItem>;
                          })}
                        </Select>
                      </TableCell>
                      <TableCell align="right">{selectedServices[s.title]["price"] * selectedServices[s.title]["qty"]} TL</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Paper
              variant="outlined"
              style={{
                marginTop: 10,
                backgroundColor: "dodgerblue",
                padding: 10,
              }}
            >
              <Typography style={{ color: "white" }} variant="body1">
                Toplam Fiyat : {makePrice(total.property, propertyInfo.general.xRate, CM.rates) + total.services} TL
              </Typography>
            </Paper>
            <Paper
              variant="outlined"
              style={{
                marginTop: 10,
                backgroundColor: "green",
                padding: 10,
              }}
            >
              <Typography style={{ color: "white" }} variant="body1">
                Ön Ödeme :{" "}
                {((makePrice(total.property, propertyInfo.general.xRate, CM.rates) + total.services) * (propertyInfo.depositRate ?? 1)) /
                  100}{" "}
                TL
              </Typography>
            </Paper>
          </TableContainer>
        </CardContent>
      </Card>
    </Paper>
  );
}
