import React from "react";
import { Typography } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";

export default function CardFooter({ icon, title }) {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      {icon}
      <Typography
        style={{
          color: "#4D4D4D",
          fontSize: 14,
          marginLeft: 5,
        }}
        variant="body1"
      >
        {title}
      </Typography>
    </div>
  );
}
