import React, { useContext } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TabDetails from "./TabDetails";
import TabMap from "./TabMap";
import TabPrices from "./TabPrices";
import TabYoutube from "./TabYoutube";
import { Paper } from "@material-ui/core";
import makePrice from "../../helpers/makePrice";
import AppContext from "../../context/AppContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ListingDetailTab = ({ propertyInfo }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { CM } = useContext(AppContext);

  return (
    <Paper variant="outlined" style={{ borderRadius: 0 }}>
      <AppBar
        position="static"
        variant="outlined"
        style={{
          backgroundColor: "white",
          color: "#4D4D4D",
          borderWidth: 0,
          borderBottomWidth: 1,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Detaylar" {...a11yProps(0)} />
          <Tab label="Fiyat&Takvim" {...a11yProps(1)} />
          <Tab label="Harita" {...a11yProps(2)} />
          <Tab label="Video" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel
        value={value}
        index={0}
        children={<TabDetails propInfo={propertyInfo} />}
      />
      <TabPanel value={value} index={1}>
        <TabPrices
          reservationList={propertyInfo.reservationList}
          prices={propertyInfo.prices.map((item) => ({
            ...item,
            price: makePrice(item.price, propertyInfo.general.xRate, CM.rates),
          }))}
          basePrice={makePrice(
            propertyInfo.basePrice,
            propertyInfo.general.xRate,
            CM.rates
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabMap propInfo={propertyInfo} map={propertyInfo.map} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TabYoutube videoURL={propertyInfo.features.videoUrl} />
      </TabPanel>
    </Paper>
  );
};

export default ListingDetailTab;
