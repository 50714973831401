import React, { useEffect, useState } from "react";
import Header from "../../components/header/HeaderBlog";
import Footer from "../../components/footer/Footer";
import "./Order.css";
import OrderForm from "../../components/orderForm/OrderForm";
import OrderFormCard from "../../components/orderFormCard/OrderFormCard";
import { Grid, Typography } from "@material-ui/core";
import useAPI from "../../hooks/useAPI";
import Reservation from "../../Models/Reservation";
import { Listing } from "../../Models";
import { useParams } from "react-router-dom";

const Order = (props) => {
  const basicInfo = props.location.info;

  const propAPI = useAPI(Listing.getPropertyDetails);
  const formAPI = useAPI(Reservation.addNewReservation);
  const { slug } = useParams();

  const [selectedServices, setSelectedServices] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    propAPI.request(slug);
  }, []);

  const submitReservation = async (values) => {
    // calculate service prices
    let serviceTotal = 0;
    Object.keys(selectedServices).map((code) => {
      serviceTotal +=
        selectedServices[code]["price"] * selectedServices[code]["qty"];
    });
    values["total"] = values["total"] + serviceTotal;
    values["services"] = selectedServices;
    formAPI.request(values);
  };

  return (
    <div>
      <Header />
      <Grid
        container
        spacing={1}
        xs={12}
        justifyContent="center"
        style={{ marginTop: 20 }}
      >
        <Grid item xs={12} lg={3}>
          {propAPI.loading && (
            <Typography variant="body1"> Loading ... </Typography>
          )}
          {!propAPI.loading && propAPI.response && (
            <OrderFormCard
              orderInfo={basicInfo}
              propertyInfo={propAPI.response.data[0]}
              onAdditionalInfo={(values) => {
                setSelectedServices(values.services);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {!propAPI.loading && propAPI.response && (
            <OrderForm
              nights={basicInfo.nights}
              fixedDeposit={propAPI.response.data[0].fixedDeposit || "N/A"}
              response={formAPI.response}
              error={formAPI.error}
              loading={formAPI.loading}
              onFormSubmit={(values) =>
                submitReservation({
                  ...values,
                  ...basicInfo,
                })
              }
            />
          )}
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Order;
