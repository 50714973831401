import React, { useContext, useEffect } from "react";
import Header from "../../components/header/HeaderBlog";
import Footer from "../../components/footer/Footer";
import ListingProperty from "../../components/listingProperty/ListingProperty";
import { Listing } from "../../Models";
import useAPI from "../../hooks/useAPI";
import { Container, Grid } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import SearchBar from "../../components/searchBar/SearchBar";
import AppContext from "../../context/AppContext";

const Listings = (props) => {
  // States
  const { loading, error, response, request } = useAPI(Listing.filterItems);
  const history = useHistory();

  const { DM } = useContext(AppContext);

  const location = useLocation();
  const queries = queryString.parse(location.search);
  const { page = 1 } = queries;

  Object.keys(queries).map((key) => {
    if (["category", "mainCategory"].includes(key)) queries[key] = queries[key].replace(/%20/g, "");
  });

  useEffect(() => {
    request({ ...queries });
  }, [location.search]);

  return (
    <>
      <Header />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <div
              style={{
                position: "sticky",
                top: 10,
                backgroundColor: "white",
                zIndex: 50,
              }}
            >
              <SearchBar initialValues={queries} sidebar />
              {DM.isDesktop && (
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    count={response?.data.meta.totalPages}
                    defaultPage={Number(page)}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(_, page) =>
                      window.open(
                        `/listings?${queryString.stringify({
                          ...queries,
                          page,
                        })}`,
                        "_self"
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} lg={8}>
            <div style={{ marginTop: 10 }}>
              <ListingProperty items={response?.data?.props || []} />
            </div>
            {DM.isMobile && (
              <Pagination
                count={response?.data.meta.totalPages}
                defaultPage={Number(page)}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(_, page) =>
                  window.open(
                    `/listings?${queryString.stringify({
                      ...queries,
                      page,
                    })}`,
                    "_self"
                  )
                }
                style={{ width: "100%", marginBlock: 10 }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Listings;
