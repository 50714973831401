import React, { useContext } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import "../searchBar/SearchBar.css";
import { Formik } from "formik";
import AppContext from "../../context/AppContext";
import DatePicker from "react-multi-date-picker";
import gregorian_tr from "../../config/gregorian_tr";
import queryString from "query-string";

const SearchBar = ({ sidebar = false, initialValues = {} }) => {
  const { categories } = useContext(AppContext);
  const handleFormSubmit = (values) => {
    Object.keys(values).map((key) => {
      if (!values[key]) delete values[key];
      if (Array.isArray(values[key])) values[key] = values[key].join(".");
    });
    window.open(`listings?${queryString.stringify(values)}`);
  };
  return (
    <div
      style={{
        padding: 20,
        ...(!sidebar && { marginTop: -10, backgroundColor: "#E9E9E9" }),
        ...(sidebar && {
          border: "1px solid #CECECE",
          backgroundColor: "#F9F9F9",
          marginTop: 10,
        }),
      }}
    >
      <Formik
        initialValues={{
          district: "",
          price: "",
          rooms: "",
          category: "",
          selectedDates: [],
          ...initialValues,
        }}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, handleChange, setFieldValue, values }) => (
          <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={6} lg={sidebar ? 12 : 2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Bölge</InputLabel>
                  <Select
                    name="district"
                    value={values["district"]}
                    onChange={handleChange("district")}
                    label="Bolge"
                  >
                    <MenuItem value="">Hepsi</MenuItem>
                    {[
                      { label: "Kalkan", value: "Kalkan" },
                      { label: "Kaş", value: "KAŞ" },
                      { label: "Fethiye", value: "Fethiye" },
                    ].map(({ label, value }) => {
                      return <MenuItem value={value}>{label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={sidebar ? 12 : 2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel> Odalar </InputLabel>
                  <Select
                    label="Odalar"
                    fullWidth
                    value={values["rooms"]}
                    variant="outlined"
                    onChange={handleChange("rooms")}
                  >
                    {[...Array(10).keys()].map((index) => {
                      return <MenuItem value={index + 1}>{index + 1}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={sidebar ? 12 : 2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Fiyat Aralığı</InputLabel>
                  <Select
                    value={values["price"]}
                    name="price"
                    variant="outlined"
                    label="Fiyat Aralığı"
                    onChange={handleChange("price")}
                  >
                    <MenuItem value=""> All </MenuItem>
                    <MenuItem value="1000.5000">1.000 - 5.000 TRY</MenuItem>
                    <MenuItem value="5000.10000">5.000 - 10.000 TRY</MenuItem>
                    <MenuItem value="10000.20000">10.000 - 20.000 TRY</MenuItem>
                    <MenuItem value="20000.40000">20.000 - 40.000 TRY</MenuItem>
                    <MenuItem value="50000.99999999999">50.000+</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={sidebar ? 12 : 2}>
                <FormControl fullWidth>
                  <DatePicker
                    locale={gregorian_tr}
                    range
                    render={(value, openCalendar) => {
                      return (
                        <Button
                          onClick={openCalendar}
                          color="primary"
                          fullWidth
                          style={{ height: 56 }}
                          variant="outlined"
                        >
                          {"Giriş Çıkış Tarihi"}
                        </Button>
                      );
                    }}
                    onChange={(values) =>
                      setFieldValue(
                        "selectedDates",
                        values.map((v) => v.format("YYYY-MM-DD"))
                      )
                    }
                    value={values["selectedDates"]}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={sidebar ? 12 : 2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Detaylı Arama</InputLabel>
                  <Select
                    name="category"
                    value={values.category.split(",")}
                    label="Detaylı Arama"
                    onChange={(e) => setFieldValue("category", e.target.value.join(","))}
                    multiple
                    renderValue={(selected) => selected.join(", ")}
                  >
                    <MenuItem value="">All</MenuItem>
                    {categories.map((cat) =>
                      cat.categories.map((sc) => {
                        return (
                          <MenuItem value={sc.category}>
                            <Checkbox checked={values["category"].indexOf(sc.category) > -1} />
                            <ListItemText primary={sc.category} />
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={sidebar ? 12 : 2}>
                <Button
                  fullWidth
                  style={{ height: 56 }}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Arama
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SearchBar;
