import apiClient from "../helpers/api";
import cleanQuery from "../helpers/cleanQuery";

const filterItems = (values) =>
  apiClient.get("/property/en", { ...cleanQuery(values) });

const calculatePrice = (code, values) => {
  return apiClient.get("/property/" + code + "/prices/calculate", {
    ...cleanQuery(values),
  });
};

const getSimilarVillas = (code) => {
  return apiClient.get("/property/" + code + "/similar");
};

const getPropertyDetails = (slug) => {
  return apiClient.get("/property/slug/" + slug);
};

export default {
  filterItems,
  calculatePrice,
  getPropertyDetails,
  getSimilarVillas,
};
