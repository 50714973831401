import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export default function BlogCard({ blog }) {
  return (
    <div>
      <Card
        style={{
          backgroundColor: "#F9F9F9",
          borderRadius: 0,
          elevation: 0,
          overflow: "hidden",
        }}
      >
        <CardActionArea onClick={() => window.open("/blog/" + blog.id, "_self")}>
          <CardMedia
            component="img"
            image={blog.image}
            title={blog.title}
            style={{ height: 150, objectFit: "contain", paddingBlock: 20 }}
          />
        </CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h6">
            {blog.title}
          </Typography>
        </CardContent>
        <CardActions style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            onClick={() => window.open("/blog/" + blog.id, "_self")}
            size="small"
            color="primary"
          >
            DEVAMINI OKU
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
