import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
const MainCarousel = ({ sliders, width, height }) => {
  if (!sliders) return <></>;
  return (
    <div style={{ width: width, flex: 1 }}>
      <SimpleImageSlider
        slideDuration={0.5}
        showBullets
        width="100%"
        style={{
          position: "relative",
          marginBottom: 10,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        showNavs
        height={height}
        images={sliders}
      />
    </div>
  );
};

export default MainCarousel;
